<template>
  <el-dialog :visible="dialogVisible" @close="closeDialog" width="80%" center>
    <div
      style="line-height: 50px; padding: 50px; font-size: 20px; color: black"
    >
      <div
        style="
          text-align: center;
          font-size: 50px;
          font-weight: 500;
          margin-bottom: 50px;
        "
      >
        分群数据服务协议
      </div>
      <div style="text-align: right">版本生效日期：2024年1月4日</div>
      <div class="tag_title">提示条款</div>
      <div class="little_tag">
        欢迎您与长沙分群大数据有限公司（以下简称“分群数据”）共同签署《分群数据服务协议》（以下简称“本协议”）并使用分群数据服务！请您务必审慎阅读、充分理解各条款的内容,特别是免除或者限制责任的条款、法律适用和争议解决条款。
        <span style="font-weight: 700; text-decoration: underline"
          >如您对协议有任何疑问,可以向客服和相关业务部门进行咨询。</span
        >
      </div>
      <div class="little_tag">
        <span style="font-weight: 700; text-decoration: underline"
          >当您阅读并点击同意本协议或以其他方式选择接受本协议后,即表示您已充分阅读、理解并接受本协议的全部内容,并与分群数据达成一致。本协议自您通过网络页面点击确认或以其他方式选择接受本协议之日起成立。</span
        >阅读本协议的过程中,如果您不同意本协议或其中任何条款约定,请勿进行签约动作。
      </div>
      <div></div>
      <div class="tag_title">通用服务条款</div>
      <div class="little_title">1、签约主体及协议范围</div>
      <div class="little_tag">
        本服务协议是您与长沙分群大数据有限公司就您使用分群数据服务所签署的服务协议。
      </div>

      <div class="little_title">2、服务内容</div>
      <div class="little_tag">
        本条款中“服务”指：分群数据<a href="https://retail-api.fenqunshuju.com"
          >https://retail-api.fenqunshuju.com</a
        >网站和客户端所展示的、您申请订购且分群数据同意向您提供的服务。
      </div>
      <div class="little_title">3、服务费用</div>
      <div class="little_tag">
        3.1
        服务费用将在您订购页面予以列明公示,您可自行选择具体服务类型并按列明的价格予以支付。
      </div>
      <div class="little_tag">
        3.2
        在您付费之后,分群数据开始为您提供服务：向您发送登录、使用服务的密钥、口令即可使用服务,服务期限自您获得登录、使用服务的密钥、口令时起算。
      </div>

      <div class="little_tag">
        3.3
        服务期满双方愿意继续合作的,您至少应在服务期满前7天内支付续费款项,以使服务得以继续进行。
      </div>

      <div class="little_tag">
        3.4
        分群数据平台提供的服务均为数字化商品,基于互联网服务的特殊性和国家相关管理规定,数字化商品<span
          style="font-weight: 700"
          >不支持七天无理由退货</span
        >。除因分群数据提供的服务存在质量瑕疵致使用户无法使用服务的、本协议另有约定、法律法规要求必须退款的,分群数据应当根据退款流程予以退款。
      </div>

      <div class="little_tag">
        3.5
        如您购买的服务期限为1年,需要提前中止结束服务时,首年使用费不退。分群数据收取的客群设备押金,在您返设备且能正常使用时退还。如您未返还设备或设备不能正常使用、设备有损毁等情况,押金分群数据不予退还。
      </div>
      <div
        class="little_tag"
        style="font-weight: 700; text-decoration: underline"
      >
        3.6
        用户对分群数据平台提出退款申请,或对退款要求、退款流程等相关问题有任何疑问的,可直接拨打客服电话19198224680进行咨询。
      </div>
      <div class="little_title">4、您的权利和义务</div>

      <div class="little_tag">
        4.1
        成功订购服务后,您有权要求分群数据按照本服务协议以及分群数据网站相关页面所展示的服务说明、服务内容等向您提供服务。
      </div>
      <div class="little_tag"></div>

      <div class="little_tag">
        4.2 您负责保障分群数据采集所需的持续稳定供电和网络环境。
      </div>

      <div class="little_tag">
        4.3
        您负责分群数据采集设备的正常使用和设备财产安全,应按照设备的操作说明和使用方法正确使用设备,并保证设备处于正常工作状态。
      </div>

      <div class="little_tag">
        4.4 您不得将设备用于非法用途或转租、转借给第三方。
      </div>
      <div class="little_tag">
        4.5
        您应对设备进行合理维护和保养,保证设备的完好性和安全性。如设备发生故障或损坏,您应立即通知分群数据,并按照分群数据要求采取相应的修理措施。
      </div>
      <div class="little_tag">
        4.6
        您承认分群数据存放在客群采集设备上的任何资料、软件、数据等的知识产权均与您无关,您无权复制、传播、转让、许可或提供他人使用这些资源。
      </div>
      <div class="little_title">5、分群数据的权利、义务</div>
      <div class="little_tag">
        5.1 数据采集设备及安装施工属于分群数据固定资产,由分群数据负责提供。
      </div>
      <div class="little_tag">5.2 分群数据应按照约定提供服务。</div>
      <div class="little_tag">
        5.3 服务期限内,分群数据将为您提供如下售后服务：
      </div>
      <div class="little_tag">
        5.3.1 分群数据将提供7×24电话咨询服务,解答您在使用中的问题；
      </div>
      <div class="little_tag">
        5.3.2
        分群数据将为您提供故障支持服务,您应通过电话申报故障；分群数据将及时就您非人为操作所出现的故障提供支持,但因您的人为原因和/或不可抗力、以及其他非分群数据控制范围内的事项除外。
      </div>
      <div class="little_tag">
        5.4
        鉴于互联网的特殊性,因黑客、病毒、电信部门技术调整等引起的事件造成无法访问或访问速度下降或故障是正常情况,您认同不属于分群数据违约。同时,分群数据作为服务提供方端尽全力避免受到黑客攻击,保证服务的完整性和持续性。
      </div>
      <div class="little_title">6、保密条款</div>
      <div class="little_tag">
        6.1
        在提供数据的过程中及接收数据以后,双方应按照法律规定、监管要求以及相应国家或行业标准,采取必要的技术措施和管理措施,以确保数据的安全,防止数据遭到篡改、破坏、泄露、非法获取或非法利用。
      </div>
      <div class="little_tag">
        6.2
        双方应采取适当措施妥善保存对方提供的保密信息,措施的审慎程度不少于其保护自身的保密信息时的审慎程度。双方仅能将保密信息用于与本协议项下的有关用途或目的。
      </div>
      <div class="little_tag">
        6.3
        双方保证保密信息仅可在各自一方从事该业务的负责人和雇员范围内知悉,并严格限制接触上述保密信息的员工遵守本条之保密义务。
      </div>
      <div
        class="little_tag"
        style="font-weight: 700; text-decoration: underline"
      >
        6.4
        您和分群数据都应尽最大的努力保护上述保密信息不被泄露。一旦发现有上述保密信息泄露事件,双方应合作采取一切合理措施避免或者减轻损害后果的产生。
      </div>
      <div class="little_title">7、违约和争议处理</div>
      <div class="little_tag">
        因本协议以及本协议项下订单/附件/补充协议等（如有）引起或有关的任何争议,由协议各方协商解决,也可由有关部门调解。协商或调解不成的,应向长沙市开福区人民法院起诉。
      </div>
      <div class="little_title">8、不可抗力</div>
      <div class="little_tag">
        8.1
        因不可抗力或者其他意外事件,使得本服务条款的履行不可能、不必要或者无意义的,遭受不可抗力、意外事件的一方不承担责任。
      </div>
      <div class="little_tag">
        8.2
        不可抗力、意外事件是指不能预见、不能克服并不能避免且对一方或双方当事人造成重大影响的客观事件,包括自然灾害如洪水、地震、瘟疫流行等以及社会事件如战争、动乱、政府行为、电信主干线路中断、黑客、网路堵塞、电信部门技术调整和政府管制等。
      </div>
      <div class="little_title">9、附则</div>
      <div class="little_tag">
        9.1
        本协议的附件,以及分群数据在分群数据网站相关页面上的服务说明、价格说明和您确认同意的订购页面（包括产品的专用条款、服务说明、操作文档等）均为本协议不可分割的一部分。如遇不一致之处,以（1）服务说明、价格说明、其他订购页面,（2）专用条款和附件,（3）本协议通用条款的顺序予以适用。
      </div>
      <div class="little_tag">
        9.2
        您承诺在遵守中华人民共和国出口管制和经济制裁法律法规的前提下,同时遵守本合同所适用的其他出口管制法律法规。您承诺不会将分群数据提供的产品或服务直接或间接地提供给适用的出口管制和经济制裁法律所列出的制裁国家/地区或受限名单上的实体或个人,也不会直接或间接地用于适用的出口管制和经济制裁法律法规禁止的用途。
      </div>
      <div
        class="little_tag"
        style="font-weight: 700; text-decoration: underline"
      >
        9.3
        如本协议内容发生变动,分群数据应通过提前30天在分群数据网站的适当版面公告向您提示修改内容；如您继续使用分群数据服务,则视为您接受分群数据所做的相关修改。
      </div>
      <div class="little_tag">
        9.4
        本协议任一条款被视为废止、无效或不可执行,该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。
      </div>
      <div class="little_tag">
        9.5
        如有任何疑问,请直接拨打客服电话19198224680进行咨询或发送邮件至客服邮箱：kefu@fenqunshuju.com。
      </div>
    </div>
  </el-dialog>
</template>
  
  <script>
export default {
  props: {
    dialogVisible: {
      // 对话框可见性状态
      type: Boolean,
      required: true,
    },
    title: {
      // 对话框标题
      type: String,
      required: true,
    },
  },
  methods: {
    closeDialog() {
      // 关闭对话框的方法
      this.$emit("update:dialogVisible", false);
    },
  },
};
</script>
  
  <style>
.little_tag {
  text-indent: 27px;
}
.tag_title {
  font-weight: 700;
  font-size: 30px;
}
.little_title {
  font-weight: 700;
  font-size: 25px;
}
</style>
  