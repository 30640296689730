<template>
  <div class="allmain">
    <div class="header">
      <div class="header_logo">
        <img src="../../assets/logo.png" />
      </div>
      <div class="header_center">
        <div @click="gohome">首页</div>
        <div @click="goproductIntroduction">产品介绍</div>
        <div @click="gocompanyprofile">公司简介</div>
        <!-- <div style="color: #2558e4" @click="gopayorder">立即购买</div> -->
      </div>
      <div class="header_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <img src="../../assets/gopay.png" />
    </div>
    <div class="main_center">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="立即购买" name="buyNow">
          <div class="center_buy">
            <div
              class="buy_tag"
              :class="{ selectedbtn: buyActiveName === index }"
              @click="selectBuyService(item, index)"
              v-for="(item, index) in imgList"
              :key="index"
            >
              <img :src="item.img" />
            </div>
          </div>
          <div style="margin: 40px 0px" v-if="this.buyActiveName === 0">
            <div>
              <div style="margin-bottom: 10px">
                <img src="../../assets/buyimg/purchasingInstructions.png" />
              </div>
              <div class="buy_title">
                <el-row :gutter="20">
                  <el-col
                    :span="11"
                    class="tag_icon"
                    v-for="(item, index) in tagList"
                    :key="index"
                  >
                    <img src="../../assets/buyimg/tag_three.png" /><span>{{
                      item
                    }}</span>
                  </el-col>
                </el-row>
              </div>

              <div>
                <el-form
                  label-position="right"
                  label-width="110px"
                  :model="addOrder"
                  :rules="rules"
                  ref="ruleForm"
                >
                  <el-form-item label="购买数量：" prop="orderNum">
                    <el-input
                      style="width: 20%"
                      type="number"
                      placeholder="请输入购买数量"
                      @change="change"
                      v-model="addOrder.orderNum"
                    ></el-input>
                    <span style="margin-left: 10px">组</span>
                  </el-form-item>
                  <el-form-item label="企业名称：" prop="companyName">
                    <el-input
                      style="width: 30%"
                      placeholder="请输入企业名称"
                      v-model="addOrder.companyName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="企业联系人：" prop="linkman">
                    <el-input
                      style="width: 30%"
                      placeholder="请输入企业联系人"
                      v-model="addOrder.linkman"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话：" prop="phone">
                    <el-input
                      style="width: 30%"
                      type="number"
                      placeholder="请输入联系电话"
                      v-model="addOrder.phone"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="对公转账：">
                    <div class="totalPrice">
                      <b
                        >¥{{
                          this.addOrder.locationOrder *
                          this.addOrder.orderNum *
                          2
                        }}</b
                      >
                      <span
                        >（其中包含押金￥{{
                          this.addOrder.locationOrder * this.addOrder.orderNum
                        }}，下单之后在我的订单中查看对公转账信息）</span
                      >
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-checkbox v-model="checked" />
                    <span style="margin-left: 10px; text-wrap: nowrap"
                      >勾选，购买并同意<el-link
                        type="primary"
                        :underline="false"
                        @click="submitAgreement()"
                        >《分群数据服务协议》</el-link
                      ></span
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div style="text-align: center">
                <el-button class="buyBtn" @click="goBuyNow('ruleForm')"
                  >立即下单</el-button
                >
              </div>
            </div>
          </div>

          <div style="margin: 40px 0px" v-if="this.buyActiveName === 1">
            <div>
              <div style="margin-bottom: 10px">
                <img src="../../assets/buyimg/purchasingInstructions_2.png" />
              </div>
              <div class="buy_titleTwo">
                <el-row :gutter="20">
                  <el-col
                    :span="24"
                    class="tag_icon"
                    v-for="(item, index) in tagTwoList"
                    :key="index"
                  >
                    <img src="../../assets/buyimg/tag_three_2.png" /><span>{{
                      item
                    }}</span>
                  </el-col>
                </el-row>
              </div>

              <div class="setMealList">
                <div
                  class="setMeal"
                  :class="{ selectMeal: mealActiveName === index }"
                  @click="selectBuyMeal(item, index)"
                  v-for="(item, index) in mealList"
                  :key="index"
                >
                  <div class="meal_title">{{ item.name }}</div>
                  <div class="meal_price">
                    ¥<b>{{ item.value }}</b>
                  </div>
                </div>
              </div>

              <div>
                <el-form
                  label-position="right"
                  label-width="110px"
                  :model="addOrder"
                  :rules="rules"
                  ref="ruleForm"
                >
                  <el-form-item label="购买数量：" prop="orderNum">
                    <el-input
                      style="width: 20%"
                      type="number"
                      placeholder="请输入购买数量"
                      v-model="addOrder.orderNum"
                      @change="change"
                    ></el-input>
                    <span style="margin-left: 10px">组</span>
                  </el-form-item>
                  <el-form-item label="企业名称：" prop="companyName">
                    <el-input
                      style="width: 30%"
                      placeholder="请输入企业名称"
                      v-model="addOrder.companyName"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="企业联系人：" prop="linkman">
                    <el-input
                      style="width: 30%"
                      placeholder="请输入企业联系人"
                      v-model="addOrder.linkman"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话：" prop="phone">
                    <el-input
                      style="width: 30%"
                      type="number"
                      placeholder="请输入联系电话"
                      v-model="addOrder.phone"
                    ></el-input>
                  </el-form-item>

                  <el-form-item label="对公转账：">
                    <div class="totalPrice_2">
                      <b
                        >¥{{
                          this.addOrder.locationOrder * this.addOrder.orderNum
                        }}</b
                      >
                      <span>（下单之后在我的订单中查看对公转账信息）</span>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-checkbox v-model="checked" />
                    <span style="margin-left: 10px; text-wrap: nowrap"
                      >勾选，购买并同意<el-link
                        type="primary"
                        :underline="false"
                        @click="submitAgreement()"
                        >《分群数据服务协议》</el-link
                      ></span
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div style="text-align: center">
                <el-button class="buyBtn" @click="goBuyNow('ruleForm')"
                  >立即下单</el-button
                >
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的订单" name="myOrder">
          <div v-if="showOrder">
            <div class="order_center">
              <div style="padding: 40px 0px 10px 0px">
                <el-form label-position="right" label-width="150px">
                  <el-form-item label="开户名称：">
                    {{ this.accountName }}
                    <el-button
                      style="border: none"
                      circle
                      icon="el-icon-document-copy"
                      @click="copyText(accountName)"
                    ></el-button>
                  </el-form-item>
                  <el-form-item label="开户银行：">
                    {{ this.accountBank }}
                    <el-button
                      style="border: none"
                      circle
                      icon="el-icon-document-copy"
                      @click="copyText(accountBank)"
                    ></el-button>
                  </el-form-item>
                  <el-form-item label="专属汇款账号：">
                    {{ this.accountRemittance }}
                    <el-button
                      style="border: none"
                      circle
                      icon="el-icon-document-copy"
                      @click="copyText(accountRemittance)"
                    ></el-button>
                  </el-form-item>
                </el-form>
              </div>
            </div>
            <div class="order_top">
              <div class="order_title">
                <div>
                  <span style="color: #777777">订单编号：</span>
                  <b>{{ this.myOrderData.orderListNum }}</b>
                </div>
                <div>下单时间：{{ this.myOrderData.time }}</div>
              </div>
              <div class="order_main">
                <el-form label-position="right" label-width="100px">
                  <el-form-item label="购买服务：">
                    <div style="font-weight: 700">
                      {{ this.myOrderData.orderType }}
                    </div>
                  </el-form-item>
                  <el-form-item label="店铺数量：">
                    <div>x {{ this.myOrderData.orderNum }} 组</div>
                  </el-form-item>
                  <el-form-item label="应付金额：">
                    <div style="color: red; font-weight: 700; font-size: 18px">
                      ¥ {{ this.myOrderData.totalPrice }}
                    </div>
                  </el-form-item>
                  <el-form-item label="企业名称：">
                    <div>
                      {{ this.myOrderData.companyName }}
                    </div>
                  </el-form-item>
                  <el-form-item label="企业联系人：">
                    <div>
                      {{ this.myOrderData.linkman }}
                    </div>
                  </el-form-item>
                  <el-form-item label="联系电话：">
                    <div>
                      {{ this.myOrderData.phone }}
                    </div>
                  </el-form-item>
                </el-form>
              </div>
            </div>
          </div>
          <div v-else style="text-align: center; margin-top: 10%">
            <img src="../../assets/shoppingCart.png" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="main_bottom">
      <div class="main_bottom_bg">
        <div class="main_bottom_apply">
          <div>
            <img src="../../assets/apply.png" />
          </div>
          <div>
            <span>填写入驻申请，专人联系为您服务！</span>
          </div>
          <div>
            <img src="../../assets/code.png" />
          </div>
          <div></div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_logo">
        <img src="../../assets/fqsj_2.png" />
      </div>
      <div class="footer_right">
        <div>
          <span>联系我们</span>
        </div>
        <div>
          <img src="../../assets/wechatcode.png" />
        </div>
        <div>
          <div class="header_right_wechat">
            <img src="../../assets/wechat_on.png" />
            <div>fenqunshuju</div>
          </div>
          <div class="header_right_wechat">
            <img src="../../assets/phone_on.png" />
            <div>19967004532</div>
          </div>
        </div>
      </div>
    </div>
    <div class="record">
      <div>
        <a href="https://beian.miit.gov.cn" target="_blank">湘ICP备2023024436号</a>
      </div>
      <div>联系地址：开福区秀峰街道秀峰商贸城12栋2号101</div>
    </div>
    <agreement :dialog-visible.sync="dialogVisible" title="分群数据服务协议" />
  </div>
</template>
  
  <script>
import moment from "moment";
import axios from "axios";
import agreement from "../../components/agreement.vue";
export default {
  components: {
    agreement,
  },
  data() {
    return {
      cardData: [
        {
          name: "客群数字化采集",
          img: "card_1.png",
          value:
            "采集过店客群、进店客群的着装、特征、喜好等等数据，将客群进行分群。",
        },
        {
          name: "客群指标分析",
          img: "card_2.png",
          value: "针对客群分群的数据指标分析门店客群关系以及客群的趋势变化。",
        },
        {
          name: "业绩成交漏斗",
          img: "card_3.png",
          value:
            "分析门店的业绩结构，通过成交漏斗的关系将影响门店的关键点展示出来。",
        },
        {
          name: "门店客群对比",
          img: "card_4.png",
          value:
            "对门店的各个客群的数据指标进行比较，划分主力客群及潜力客群，发掘潜在顾客。",
        },
      ],
      incrementCardData: [
        {
          name: "客群预测",
          img: "increment_1.png",
          value:
            "根据以往的客群采集数据，分析客群构成以及变化趋势，对未来几天的过店客群进行预测。",
        },
        {
          name: "优化商品营销",
          img: "increment_2.png",
          value: "根据采集数据，分析主力客群，针对主力客群优化商品营销策略。",
        },
        {
          name: "发掘潜客增量",
          img: "increment_3.png",
          value:
            "分析门店的业绩结构，通过成交漏斗的关系将影响门店的关键点展示出来。",
        },
        {
          name: "增量品类异业连接",
          img: "increment_4.png",
          value:
            "对门店的各个客群的数据指标进行比较，划分主力客群及潜力客群，发掘潜在顾客。",
        },
      ],
      rules: {
        orderNum: [
          {
            required: true,
            message: "请输入购买数量",
            trigger: "blur",
          },
        ],
        companyName: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
        ],
        linkman: [
          { required: true, message: "请输入企业联系人", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      },
      activeName: "buyNow",
      checked: false,
      addOrder: {
        orderType: "客群数据",
        locationOrder: 1825,
        companyName: "",
        linkman: "",
        phone: "",
        orderNum: 1,
        totalPrice: "",
      },
      accountName: "长沙分群大数据有限公司",
      accountBank: "中国银行股份有限公司长沙市湘江北路支行",
      accountRemittance: "595080622186",
      myOrderData: "",
      showOrder: false,
      buyActiveName: 0,

      imgList: [
        {
          name: "客群数据",
          value: 1825,
          img: require("../../assets/buyimg/tag_one.png"),
        },
        {
          name: "选址数据",
          value: 1500,
          img: require("../../assets/buyimg/tag_two.png"),
        },
      ],
      tagList: [
        "分群为您提供过店及进店得客群数据分析！",
        "安装设备时需提供网络及电源畅通！",
        "设备安装后调试好后，会为您提供WEB及APP端得账号分配！",
        "安装设备后需保证设备的安全使用和运行！每组数据关联的设备需相当于1年使用费的押金！",
        "购买服务后，分群将持续为您陪跑，软件使用、数据解读等！",
        "获取数据后需要对数据保密，不可二次交易。",
        "购买服务后，如需与第三方POS对接，分群将为您提供技术支持!",
      ],

      tagTwoList: [
        "购买服务后，分群将为您提供全面店址过店客群的相关数据！（时段客群的构成数据变化、天气温度对客群的数据变化影响、分析门店的主力客群潜力客群！）",
        "获得店址的客群数据报告后不可用作二次交易！",
      ],
      mealList: [
        {
          name: "3天",
          value: 1500,
          day: 3,
        },
        {
          name: "7天",
          value: 1600,
          day: 7,
        },
        {
          name: "15天",
          value: 1700,
          day: 15,
        },
        {
          name: "30天",
          value: 1800,
          day: 30,
        },
        {
          name: "90天",
          value: 2000,
          day: 90,
        },
        {
          name: "半年",
          value: 2400,
          day: 180,
        },
        {
          name: "1年",
          value: 3000,
          day: 365,
        },
      ],
      mealActiveName: 0,
      dialogVisible: false,
    };
  },

  mounted() {
    this.searchLocal();
  },
  methods: {
    searchLocal() {
      if (localStorage.getItem("orderData")) {
        this.showOrder = true;
        this.myOrderData = JSON.parse(localStorage.getItem("orderData"));
        // console.log(this.myOrderData, "有数据");
      } else {
        // 不存在该值，执行相应逻辑
        this.showOrder = false;
        // console.log("不存在数据");
      }
    },
    gohome() {
      this.$router.push({ name: "home" });
    },
    goproductIntroduction() {
      this.$router.push({ name: "productIntroduction" });
    },
    gocompanyprofile() {
      this.$router.push({ name: "companyprofile" });
    },
    gopayorder() {
      this.$router.push({ name: "payOrder" });
    },
    handleClick() {
      // console.log(index,"切换");
      this.buyActiveName = 0;
      this.addOrder.locationOrder = 1825;
      this.addOrder.orderNum = 1;
    },
    goBuyNow(formName) {
      // console.log(this.addOrder, "立即下单");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked !== true) {
            this.$message({
              title: "错误",
              message: "请同意该协议!",
              type: "error",
            });
          } else {
            if (this.addOrder.orderType === "客群数据") {
              axios({
                method: "post",
                url: "https://www.fenqunshuju.com/api/order/add",
                data: {
                  orderType: this.addOrder.orderType,
                  siteNum: this.addOrder.locationOrder,
                  companyName: this.addOrder.companyName,
                  linkman: this.addOrder.linkman,
                  phone: this.addOrder.phone,
                  orderNum: this.addOrder.orderNum,
                  totalPrice:
                    this.addOrder.orderNum * this.addOrder.locationOrder,
                },
              }).then((res) => {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                // console.log(res, "返回值");
                const data = moment().format("YYYY-MM-DD");
                this.orderList = {
                  orderType: this.addOrder.orderType,
                  siteNum: this.addOrder.locationOrder,
                  companyName: this.addOrder.companyName,
                  orderNum: this.addOrder.orderNum,
                  linkman: this.addOrder.linkman,
                  phone: this.addOrder.phone,
                  time: data,
                  orderListNum: res.data.data,
                  totalPrice:
                    this.addOrder.orderNum * 2 * this.addOrder.locationOrder,
                };
                localStorage.setItem(
                  "orderData",
                  JSON.stringify(this.orderList)
                );
                // console.log(this.orderList, "订单列表");
                this.checked = false;
                this.addOrder = {};
                this.activeName = "myOrder";
                this.showOrder = true;
                this.searchLocal();
              });
            } else {
              axios({
                method: "post",
                url: "https://www.fenqunshuju.com/api/order/add",
                data: {
                  orderType: this.addOrder.orderType,
                  siteNum: this.addOrder.locationOrder,
                  companyName: this.addOrder.companyName,
                  linkman: this.addOrder.linkman,
                  phone: this.addOrder.phone,
                  orderNum: this.addOrder.orderNum,
                  totalPrice:
                    this.addOrder.orderNum * this.addOrder.locationOrder,
                },
              }).then((res) => {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                // console.log(res, "返回值");
                const data = moment().format("YYYY-MM-DD");
                this.orderList = {
                  orderType: this.addOrder.orderType,
                  siteNum: this.addOrder.locationOrder,
                  companyName: this.addOrder.companyName,
                  orderNum: this.addOrder.orderNum,
                  linkman: this.addOrder.linkman,
                  phone: this.addOrder.phone,
                  time: data,
                  orderListNum: res.data.data,
                  totalPrice:
                    this.addOrder.orderNum * this.addOrder.locationOrder,
                };
                localStorage.setItem(
                  "orderData",
                  JSON.stringify(this.orderList)
                );
                // console.log(this.orderList, "订单列表");
                this.checked = false;
                this.addOrder = {};
                this.activeName = "myOrder";
                this.showOrder = true;
                this.searchLocal();
              });
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    copyText(data) {
      navigator.clipboard
        .writeText(data)
        .then(() => {
          this.$notify({
            title: "成功",
            message: "已复制到粘贴板",
            type: "success",
          });
        })
        .catch((error) => {
          console.log("文本复制失败:", error);
        });
    },

    selectBuyService(item, index) {
      // console.log(item, index, "选择服务");
      this.addOrder.orderType = item.name;
      this.buyActiveName = index;
      this.mealActiveName = 0;
      if (item.name === "客群数据") {
        // console.log("客群数据1500元");
        this.addOrder.locationOrder = 1825;
      } else {
        this.addOrder.locationOrder = 1500;
      }
    },
    selectBuyMeal(item, index) {
      // console.log(item, "点击选择选址服务类目");
      this.mealActiveName = index;
      this.addOrder.locationOrder = item.value;
    },
    submitAgreement() {
      // console.log("点击用户协议");
      this.dialogVisible = true;
    },
    change(data) {
      console.log(data, "222");
      if (data <= 0) {
        this.$message({
          showClose: true,
          message: "购买数量不能为0且不能为负数",
          type: "error",
        });
      }
      //强制刷新或者使用this.$set方法
      this.$forceUpdate();
    },
  },
};
</script>
  
  <style scoped>
.allmain {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
.header {
  height: 100px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}
.header_right {
  display: flex;
  align-items: center;
}
.header_right div {
  margin: 0 10px;
}
.header_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.header_center {
  width: 50%;
  display: flex;
}
.header_center div {
  margin: 0px 50px;
}
.main img {
  width: 100%;
}
.main_bottom {
  width: 100%;
  background: url("../../assets/FQSJ_logo.png") no-repeat;
  background-size: 100% 100%;
}
.main_bottom_bg {
  text-align: center;
  height: 320px;
}
.main_bottom_apply div:nth-child(1) {
  padding: 80px 0px 20px 0px;
}
.main_bottom_apply div:nth-child(2) {
  color: #777777;
  font-size: 14px;
  padding-bottom: 20px;
}
.main_bottom_apply .el-button {
  width: 160px;
  height: 42px;
  color: white;
  background-color: #2558e4;
  border-radius: 10px;
}
.footer {
  background-color: #111111;
  color: white;
  height: 100px;
  padding: 0px 12%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_right {
  display: flex;
  align-items: center;
}
.footer_right div {
  margin: 0 10px;
}
.footer_right_wechat {
  padding: 5px 0px;
  display: flex;
  align-items: center;
}
.record {
  height: 80px;
  color: white;
  padding: 0 10%;
  background-color: #3d3d3d;
  width: 80%;
  line-height: 40px;
}
.record div {
  margin: 0px 10px;
}
.main_center {
  min-height: 600px;
  padding: 20px 20%;
}
.buyBtn {
  width: 50%;
  border-radius: 10px;
  background-color: #2558e4;
  color: white;
  border: none;
}
.order_top {
  background-color: #f8f8f8;
  border-radius: 10px;
}
.order_title {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 20px 20px 14px 20px;
}
.order_main {
  line-height: 50px;
  padding: 20px 40px;
}
.order_main .el-form-item {
  margin-bottom: 0px;
}
.order_center {
  margin: 20px 0px;
  background: url("../../assets/buyimg/background_3.png") no-repeat;
  background-size: 100% 100%;
}

.center_buy {
  display: flex;
}
.center_buy img {
  width: 100%;
  height: 100%;
}
.buy_tag {
  margin-right: 20px;
  border-radius: 8px;
  border: 1px solid white;
}
.selectedbtn {
  display: inline-block;
  border-radius: 8px;
  border: 1px solid #144add;
}
.buy_title {
  padding: 20px 25px;
  background-image: url("../../assets/buyimg/background_1.png");
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: 20px;
}

.buy_titleTwo {
  padding: 20px 25px;
  background-image: url("../../assets/buyimg/background_2.png");
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin-bottom: 20px;
}
.tag_icon {
  display: flex;
  align-items: center;
  margin: 10px 0px;
  font-size: 14px;
  white-space: nowrap;
}
.tag_icon img {
  margin-right: 10px;
}
.setMealList {
  margin-bottom: 20px;
}
.setMeal {
  width: 17%;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.1);
  display: inline-block;
  margin: 1%;
  padding: 40px 0px;
  position: relative;
  text-align: center;
  border: 1px solid white;
}
.meal_title {
  color: white;
  width: 45%;
  background: url("../../assets/buyimg/background.png");
  background-size: 100% 100%;
  padding: 3px;
  position: absolute;
  left: 0;
  top: 0;
}

.selectMeal {
  border-radius: 11px;
  border: 1px solid #ff8127;
}
.meal_price {
  color: #ff8127;
}
.meal_price b {
  font-size: 36px;
}
.totalPrice {
  color: #999999;
}
.totalPrice b {
  font-size: 36px;
  color: #2558e4;
}
.totalPrice_2 {
  color: #999999;
}
.totalPrice_2 b {
  font-size: 36px;
  color: #ff8127;
}
.record a {
  color: white;
  text-decoration: none;
}
</style>
  